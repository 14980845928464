import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import {
  ContactCard,
  EContactCardLayout,
} from "@organic-return/foundation-react/src/components/Contact/ContactCard"
import { Search } from "@organic-return/foundation-react/src/components/Search/Search"

const PeopleSearch = ({ module, page }) => {
  const data = useStaticQuery(graphql`
  query PeopleSearchQueryCustom {
    allPerson(filter: {isHidden: {ne: true}},sort: {fields: last, order: ASC}) {
      nodes {
        bio
        company
        first
        fullName
        last
        mlsID
        officeName
        originID
        path
        email
        phone
        phoneMobile
        phoneOffice
        workTitle
        address {
          street1
          city
          state
          postal
        }
        social {
          channel
          url
        }
        photos {
          url
        }
      }
    }
  }
  `)
  // Small card size option (default)
  let cardClass = "w-[400px]"
  let cardImageWidth = 400
  // Large card size option
  if (module.customFields?.cardSize == "large") {
    cardClass = "w-[580px]"
    cardImageWidth = 580
  }

  // Configurable results per page defaults to 20
  let pageSize = module.customFields?.pageSize || 0
  return (
    <>
      <span className="agentCount">{`${data.allPerson.nodes.length} agents`}</span>
      <Search
        headerClassName="max-w-screen-lg mx-auto"
        pagerClassName="max-w-screen-lg mx-auto"
        resultsClassName="max-w-screen-xl mx-auto"
        className="inverted"
        index={data.allPerson.nodes}
        pageSize={pageSize}
        showCount={false}
        renderItem={(item, index) => {
          return (
            <ContactCard
              contact={item}
              layout={EContactCardLayout.horizontal}
              maxWidthImage={200}
              className="sm:w-1/2 xl:w-1/3 p-4"
              key={index}
              showAddress={true}
              linkFunc={props => {
                return (
                  <Link
                    to={props.href}
                    className={`text-inherit ${props.className}`}
                    key={props.key}
                    title={props.title}
                  >
                    {props.children}
                  </Link>
                )
              }}
              imageProps={{
                wrapperClassName: "h-full"
              }}
            />
          )
        }}
        preRenderItem={(item, index) => {
          return (
            <a key={index} href={item.path}></a>
          )
        }}
      />
    </>
  )
}

export default PeopleSearch
